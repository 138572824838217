import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

const innerWrapper = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}

export default ({ location, data }) => {
  return (
    <Layout location={location}>

    <div className="cover-heading-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-sm-10">
            <h1 className="cover-heading">Happy Clients</h1>
          </div>
        </div>
      </div>
    </div>

    <div className="cover-wrapper cover-wso2">
      <div className="cover-wrapper-inner">
        <div className="cover-container">
          <div className="inner cover">
          </div>
        </div>
      </div>
    </div>

    <section className="m-t-50 sm-m-t-20">
      <div className="container">
        <div style={innerWrapper}>
          <h1 className=" no-margin">WSO2</h1>
          <Link className="btn-view-project light" to={`/clients/wso2`}>View Project</Link>
        </div>
      </div>
    </section>



    <div className="cover-wrapper cover-joinem m-t-100 sm-m-t-50">
      <div className="cover-wrapper-inner">
        <div className="cover-container">
          <div className="inner cover">
          </div>
        </div>
      </div>
    </div>

    <section className="m-t-50 sm-m-t-20">
      <div className="container">
        <div style={innerWrapper}>
          <h1 className=" no-margin">Join'em</h1>
          <Link className="btn-view-project light" to={`/clients/joinem`}>View Project</Link>

        </div>
      </div>
    </section>
   

    <div className="cover-wrapper cover-genesis m-t-100 sm-m-t-50">
      <div className="cover-wrapper-inner">
        <div className="cover-container">
          <div className="inner cover">
          </div>
        </div>
      </div>
    </div>

    <section className="m-t-50 sm-m-t-20">
      <div className="container">
        <div style={innerWrapper}>
          <h1 className=" no-margin">Genesis Media</h1>
          <Link className="btn-view-project light" to={`/clients/genesis-media`}>View Project</Link>
        </div>
      </div>
    </section>



    <div className="cover-wrapper cover-clubsoirte m-t-100 sm-m-t-50">
      <div className="cover-wrapper-inner">
        <div className="cover-container">
          <div className="inner cover">
          </div>
        </div>
      </div>
    </div>
    <section className="m-t-50 sm-m-t-20">
      <div className="container">
        <div style={innerWrapper}>
          <h1 className="no-margin">Club Soirtè</h1>
          <Link className="btn-view-project light" to={`/clients/clubsoirte`}>View Project</Link>
        </div>
      </div>
    </section>


    <div className="cover-wrapper cover-munkytees m-t-100 sm-m-t-50">
      <div className="cover-wrapper-inner">
        <div className="cover-container">
          <div className="inner cover">
          </div>
        </div>
      </div>
    </div>


    <section className="m-t-50 sm-m-t-20 m-b-50">
      <div className="container">
        <div style={innerWrapper}>
           <h1 className="no-margin">MunkyTees</h1>
          <Link className="btn-view-project light" to={`/clients/munkytees`}>View Project</Link>
        </div>
      </div>
    </section>
    </Layout>
  )
}
